import React, {useState, useEffect, useCallback} from "react";
import { database } from "../firebase";
import {Link, useParams} from 'react-router-dom';


const Manage = () => {
    const { id } = useParams();
    const [username, setUsername] = useState(id || "")
    const [secondUsername, setSecondUsername] = useState("")
    const [active, setActive] = useState("")
    const [message, setMessage] = useState("")
    useEffect(() => {
       database.ref('users/' + username).once('value',(snapshot) => {
            if (snapshot.exists()) {
                console.log("1")
                let userEntry = snapshot.val()
                if(userEntry.username) {
                    setSecondUsername(userEntry.username)
                }
                if(userEntry.active) {
                    setActive("active")
                }
                else {
                    setActive("deleted")
                }
            }
        })
    }, [username]);

    const add = useCallback(async (provider) => {
        let userRef = await database.ref('users/' + username);
        await userRef.once('value', async (snapshot) => {
            var rightNow = new Date();
            var res = rightNow.toISOString().slice(0,10).replace(/-/g,"") + "235900";
            if (snapshot.exists()) {
                console.log("1")
                let userEntry = snapshot.val()
                console.log(userEntry)
                userEntry.active = true
                userEntry.username = secondUsername
                await database.ref('users/' + username).set(userEntry)
                setMessage("readded " + username)
            } else {
                console.log("2")
                const userEntry = {
                    userId: username,
                    username: secondUsername,
                    lastRecording: res,
                    provider: provider,
                    active: true
                }
                await database.ref('users/' + username).set(userEntry)
                setMessage("added " + username)
            }
        });
    }, [username, secondUsername])

    const deleteUser =  useCallback(async () => {
        let userRef = await database.ref('users/' + username);
        await userRef.on('value', async (snapshot) => {
            if (snapshot.exists()) {
                let userEntry = snapshot.val()
                userEntry.active = false
                await database.ref('users/' + username).set(userEntry)
                setMessage("deleted " + username)
            }
            else {
                setMessage("not found " +  username)
            }
        });
    }, [username])

    const handleChange = (event) => {
        setUsername(event.target.value)
    }

    const handleSecondChange = (event) => {
        setSecondUsername(event.target.value)
    }

    const addCB = (event) => {
        add("cb")
    }

    const addC4 = (event) => {
        add("c4")
    }

    const addSC = (event) => {
        add("sc")
    }

    return <div>
        <div className="mgt-5 mgb-5">
            <Link to="/all" className="btn btn-light mgr-10 ">Back</Link>
        </div>
        <hr/>
        { message &&
        <div>{message}<hr/></div>

        }
        <div className="form-group">
            <label>
                Manu:
                <input type="text" value={username} onChange={handleChange} className="form-control"/>
                <input type="text" value={secondUsername} onChange={handleSecondChange} className="form-control"/>
                {active && <div>{active}</div>}
            </label>
        </div>
        <div className="mgt-5 mgb-5">
            <div onClick={addCB} className="btn btn-secondary mgr-10 ">Add CB</div>
            <div onClick={addC4} className="btn btn-secondary mgr-10 ">Add C4</div>
            <div onClick={addSC} className="btn btn-secondary mgr-10 ">Add SC</div>
            <div onClick={deleteUser} className="btn btn-secondary mgr-10 ">Delete</div>
        </div>
    </div>
  
};
export default Manage;